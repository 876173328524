<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar size="large" class="primary" icon="pi pi-plus" />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Add New Requirement
                    </div>
                    <div class="text-sm text-500">
                      Create new minimum entry requirement record
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="md:col-9 sm:col-12 comp-grid">
            <div class="">
              <div>
                <form
                  ref="observer"
                  tag="form"
                  @submit.prevent="startSaveRecord()"
                  @reset="resetForm"
                >
                  <div class="grid">
                    <div class="col-12">
                      <div class="mb-2 font-bold text-sm">Title *</div>
                      <div id="ctrl-title-holder">
                        <InputText
                          ref="ctrltitle"
                          v-model.trim="formData.title"
                          label="Title"
                          type="text"
                          placeholder="Title"
                          class="w-full"
                          :class="getErrorClass('title')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mb-2 font-bold text-sm">Description *</div>
                      <div id="ctrl-description-holder">
                        <div class="mb-3" :class="getErrorClass('description')">
                          <Editor
                            label="Description"
                            ref="ctrldescription"
                            placeholder="Description"
                            v-model="formData.description"
                            editorStyle="height: 180px"
                          ></Editor>
                          <!-- <small class="p-3 text-negative" v-if="invalid && validated">{{errors[0]}}</small> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="showSubmitButton" class="text-center my-3">
                    <Button
                      class="p-button-primary"
                      type="submit"
                      label="Submit"
                      icon="pi pi-send"
                      :loading="saving"
                    />
                  </div>
                </form>
                <slot :submit="submit" :saving="saving"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
//[--PAGE-IMPORT-STATEMENT--]
import { PageMixin } from "../../mixins/page.js";
import { AddPageMixin } from "../../mixins/addpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  sameAs,
  minLength,
  maxLength,
  minValue,
  maxValue,
  numeric,
  integer,
  decimal,
  ipAddress,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "addMinimumentryrequirementsPage",
  components: {
    //[--PAGE-COMPONENT-NAME--]
  },
  mixins: [PageMixin, AddPageMixin],
  props: {
    pageName: {
      type: String,
      default: "minimum_entry_requirements",
    },
    routeName: {
      type: String,
      default: "minimum_entry_requirementsadd",
    },
    apiPath: {
      type: String,
      default: "minimum_entry_requirements/add",
    },
  },
  data() {
    return {
      formData: {
        title: "",
        description: "",
      },
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Add New Minimum Entry Requirements";
      },
    },
  },
  validations() {
    let formData = {
      title: { required },
      description: { required },
    };
    return { formData };
  },
  methods: {
    ...mapActions("minimum_entry_requirements", ["saveRecord"]),
    async startSaveRecord() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Form is invalid", "", "error");
        return;
      }
      this.saving = true;
      let payload = this.normalizedFormData();
      let url = this.apiUrl;
      let data = { url, payload };
      this.saveRecord(data).then(
        (response) => {
          this.record = response.data ?? {};
          this.id = this.record["minimum_entry_requirement_id"] ?? null;
          this.saving = false;
          this.resetForm();
          this.submitted = false;
          this.closeDialogs(); // close page dialog that if opened
          this.flashMsg(this.msgAfterSave);
          this.$emit("submitted", this.record);
          if (this.redirect) this.navigateTo(`/minimum_entry_requirements`);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    resetForm() {
      this.formData = { title: "", description: "" };
      this.updateFormData();
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      return {
        "p-invalid": this.v$.formData[field].$invalid && this.submitted,
      };
    },
  },
  watch: {},
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
<style scoped></style>
